import React from "react";
export class Clock extends React.Component{

    componentDidMount() {
    }

  render(){
    return(

      <div className="card-body">
          <div id={this.props.location + "-stars-placeholder"}></div>
          <div id={this.props.location + "-twinkling-placeholder"}></div>
          <p id={this.props.location} className="card-text">
              Jan 01<br></br>
              <span className="time">00</span>
              <span className="colon">:</span>
              <span className="time">00</span>
          </p>
      </div>

    )
  }

}

export default Clock;
