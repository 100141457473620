import React from 'react';
import { BrowserRouter, Route} from 'react-router-dom';
import ReactDOM from 'react-dom';
import './index.css';
import './main.css';
import * as serviceWorker from './serviceWorker';
import Card from './Components/Card/Card';


const AppRouter = () => (
  <BrowserRouter>
  <Route
    exact path='/'
    render={props => <App {...props} />}
  />
    <Route
      exact path='/:parameter1'
      render={props => <App {...props} />}
    />
    <Route
      exact path='/:parameter1/:parameter2'
      render={props => <App {...props} />}
    />
    <Route
      exact path='/:parameter1/:parameter2/:parameter3'
      render={props => <App {...props} />}
    />
    <Route
      exact path='/:parameter1/:parameter2/:parameter3/:parameter4'
      render={props => <App {...props} />}
    />
    <Route
      exact path='/:parameter1/:parameter2/:parameter3/:parameter4/:parameter5'
      render={props => <App {...props} />}
    />
    <Route
      exact path='/:parameter1/:parameter2/:parameter3/:parameter4/:parameter5/:parameter6'
      render={props => <App {...props} />}
    />
    <Route
      exact path='/:parameter1/:parameter2/:parameter3/:parameter4/:parameter5/:parameter6/:parameter7'
      render={props => <App {...props} />}
    />
    <Route
      exact path='/:parameter1/:parameter2/:parameter3/:parameter4/:parameter5/:parameter6/:parameter7/:parameter8'
      render={props => <App {...props} />}
    />
  </BrowserRouter>
);

const App = props => {

  //Build an array of cities to loop through to create the cards
  let cities = []
  if(props.match.params){
      cities = [props.match.params.parameter1,
                props.match.params.parameter2,
                props.match.params.parameter3,
                props.match.params.parameter4,
                props.match.params.parameter5,
                props.match.params.parameter6,
                props.match.params.parameter7,
                props.match.params.parameter8]
      }


    const flagLookup = (city) => {
        switch (city.toLowerCase()) {
        case 'singapore':
          return "sg"
        case 'melbourne':
            return "au"
        case 'wellington':
              return "nz"
        case 'london':
              return "gb"
        case 'athens':
              return "gr"
        case 'vancouver':
              return "ca"
        case 'las vegas':
              return "us"
        case 'chicago':
              return "us"
        default:
          return "un"
        }
    }

    const createCards = () => {
      let cards = []
      let cityCount = Object.keys(props.match.params).length
      let rowCount = Math.ceil(cityCount/4)
      let cityIndex=0;
      console.log('City Count is ' + cityCount)
      console.log ('Row count = ' + rowCount)

      if(cityCount===0){
        cities = ["singapore", "london"]
        cityCount = 2;
      }

      //console.log(cityCount + ' cities found')
      // Outer loop to create each card
      for (let r = 0; r < rowCount; r++) {
        console.log ('r is ' + r)
        while(cityCount>cityIndex){
          console.log('cityIndex is ' + cityIndex)
          console.log('Looking at City ' + cities[cityIndex])
          let myCard = []
          myCard = <div key = {cities[cityIndex]} className="col-sm-4">
                    <Card location={cities[cityIndex]} locationShort={flagLookup(cities[cityIndex])}/>
                  </div>
          //Push the card to the array if the city has been found in the FlagLookup function
          if(flagLookup(cities[cityIndex])!=="un"){
            cards.push(myCard)
          }
          cityIndex++;
        }
      }

    return cards
    }

    return (
      <div className="container">
        <div className="row">
          {createCards()}
        </div>
      </div>
  );
};


ReactDOM.render(<AppRouter />, document.getElementById('root'));


serviceWorker.unregister();
