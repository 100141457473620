import React from "react";
import Clock from '../Clock/Clock';


const toCamelCase = (inputArray) => {
    let result = "";
    for(let i = 0 , len = inputArray.length; i < len; i++) {
      let currentStr = inputArray[i];
      let tempStr = currentStr.toLowerCase();
      if(i === 0) {
        // convert first letter to upper case (the word is in lowercase)
          tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
       }
       result +=tempStr;
    }
    return result;
}

export class Card extends React.Component{



  render(){
    return(

      <div id={this.props.location.toLowerCase() + "Card"} className="card">
        <div className="card-header">
          <span className={"card-flag flag-icon flag-icon-"+this.props.locationShort}></span><br></br>{toCamelCase(this.props.location)}
        </div>
        <Clock location={this.props.location} locationShort={this.props.locationShort}/>
      </div>

    )
  }

}

export default Card;
